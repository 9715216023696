$rqb-spacing: 0.5rem !default;
$rqb-background-color: rgba(0, 75, 183, 0.2) !default;
$rqb-border-color: red !default;
$rqb-border-style: solid !default;
$rqb-border-radius: 0.25rem !default;
$rqb-border-width: 1px !default;

$rqb-branch-indent: $rqb-spacing !default;
$rqb-branch-color: $rqb-border-color !default;
$rqb-branch-width: $rqb-border-width !default;
$rqb-branch-radius: $rqb-border-radius !default;
$rqb-branch-style: $rqb-border-style !default;

.ruleGroup {
    border: 1px dashed#D9D9D9 !important;
    background: #fff !important;
}

.ruleGroup-header,
.ruleGroup-body {
    .rule {
        background-color: #F8F8F8;
        padding: 15px;
    }

    select,
    input,
    button {
        height: 32px;
        border-radius: 4px;
        border: 1px solid #979797;
        padding: 0 5px;
        color: #979797;
        font-family: DM Sans;
        font-style: normal;
        line-height: 100%;
        font-size: 14px;
        font-weight: 600;
    }

    .ruleGroup-combinators {
        color: #fff;
        background: #3965FF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: none;
        height: 24px;
        option{
            background: #fff;
            color: #979797;
        }
    }

    .rule-remove,
    .ruleGroup-remove {
        border: none;
        background-color: #BD041A;
        font-weight: 900;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        padding-bottom: 2px;
        box-shadow: 0px 2px 4px 0px #D9D9D9;
    }

    .ruleGroup-addGroup {
        background-color: #3965FF;
        border: 1px solid #3965FF;
        color: #fff;
        margin-left: auto;
        height: 24px;
        cursor: pointer;
        box-shadow: 0px 2px 4px 0px #D9D9D9;
    }

    .ruleGroup-addRule {
        cursor: pointer;
        color: #3965FF;
        background: #fff;
        border: none;
        font-size: 20px;
        box-shadow: 0px 2px 4px 0px #D9D9D9;
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }
}